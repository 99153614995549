const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || '';
const lower = s => (s && s[0].toLowerCase() + s.slice(1)) || '';

const variants = (container, propertyName, defaultValue) => {
  const camelCase2SpacedName = propertyName
    .replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, ' $1')
    .replace(/^./, s => s.toUpperCase());
  if (container[camelCase2SpacedName]) {
    return container[camelCase2SpacedName];
  }
  const lowered = lower(camelCase2SpacedName);
  if (container[lowered]) {
    return container[lowered];
  }
  const cappedWords = propertyName.replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, '$1').replace(/^./, s => s.toUpperCase());
  if (container[cappedWords]) {
    return container[cappedWords];
  }
  return defaultValue? defaultValue : null;
};

export const getFuzzyPropertyData = (container, propertyName, defaultValue?: string) => {
  if (!container || !propertyName) {
    return null;
  }
  return container[propertyName] ? container[propertyName] : variants(container, propertyName, defaultValue);
};
