import { RequestStatus } from '../types/RequestStatus';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
// Expected websoscket event: {"imageId":"some-id", "status": "COMPLETE"}  with also an optional message property
// {"imageId":"some-id", "status": "COMPLETE", "model": "greyhawk", "attempt": 1, "message": "something"}
type AtrStatusData = { id: string; data: any };

interface ExtendedEntityAdapterState {
  updated: null | Date;
  status: RequestStatus;
}

const initialState: ExtendedEntityAdapterState = {
  updated: null,
  status: RequestStatus.NOT_LOADED,
};

const clearState = state => {
  state.entities = {};
  state.ids = [];
  state.updated = new Date();
  state.status = RequestStatus.NOT_LOADED;
};

const atrStatusAdapter = createEntityAdapter<AtrStatusData>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: atrStatus => atrStatus.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

export const atrStatusSlice = createSlice({
  name: 'atrStatus',
  initialState: atrStatusAdapter.getInitialState(initialState),
  reducers: {
    instanceAdded: atrStatusAdapter.addOne,
    instanceRemoved: atrStatusAdapter.removeOne,
    updateStatus: (state, action) => {
      const entity: AtrStatusData = { id: action.payload.imageId, data: { status: action.payload.status, model: action.payload.model, attempt: action.payload.attempt, message: action.payload.message }}
      atrStatusAdapter.upsertOne(state, entity);
      state.status = RequestStatus.SUCCESS;
      state.updated = new Date();
    },
  },
});

export const {
  selectById: selectAtrStatusById,
  selectIds: selectAtrStatusIds,
  selectEntities: selectAtrStatusEntities,
  selectAll: selectAllStatusDetects,
  selectTotal: selectTotalAtrStatus,
} = atrStatusAdapter.getSelectors((state: any) => state.atrStatus);
