import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import {
  instanceImagesAdapter,
  InstanceImageData,
  iSpyInstanceImageSlice,
  selectAll,
  selectLoadedImagesByInstance,
  selectShapesByInstanceAndImage,
  selectEntitiesByInstanceAndImage,
  selectShapesByInstance,
  selectShapesByLoadedImage,
  selectiSpySelectedShapes,
} from './IspyInstanceImageSlice';
import { selectDetectsByImageId } from './AtrSlice';
import { getFuzzyPropertyData } from '../utils/jsonPropertyUtils';

// DEV NOTE: ispy current had not way tot programatically selected a loaded image so in the case
// of multiple images, webapp can't select which one to display.
// 20250214 decision is to use a single ispy instance and load the image of interest into that instance
// existing image in that instance would be replaced.
// LOAD_IMAGE_ON_ADD flag will use the loadImage function when true, and addImage when false to help test
const LOAD_IMAGE_ON_ADD = true;
const ISPY_INTERFACE_AVAILABLE_RETRY = 10;

type InstanceData = {
  instanceId: string;
  selectedShapes: string[];
  shapes: string[];
  image: ISpyImage | null;
  // images: string[];
  lastEventTimestamp: Date;
  images: EntityState<InstanceImageData>;
};
type ISpy = { id: string; data: InstanceData };

export type ImageUrlData = { imageId: string; url: string };
export type LocationRequest = { lat: number; lon: number };
type DetectData = {
  imageId: string;
  url: string;
  color: string;
  feature: any;
  atrDetectEntities?: any[];
  batchSize?: number;
  clearDetectsOnImageChange: boolean;
};
export type DetectRequest = LocationRequest & DetectData;

type ISpyImage = {
  imageID: string;
  imageSecurity: string;
  imageDate: string;
  imageExtents: any;
  imagePath: string;
  imageGuide: string;
};

type AtrDetectViewImageType = { windowReference: any; url: string; imageId: string; feature: any; lat: any; lon: any };

const ispyAdapter = createEntityAdapter<ISpy>({
  selectId: cr => cr.id,
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

interface ExtendedEntityAdapterState {
  selectedDetects: any;
  showAll: boolean;
  iSpyOpen: boolean;
  instanceId: null | string;
  iSpyVersion: null | string;
  image: null | ISpyImage;
  viewportExtents: null | any;
  syncViewport: boolean;
  lastEventTimestamp: null | Date;
  lastError: null | any;
  winRef: null | any;
  iSpyViewportCenter: null | object;
  iSpyViewportScale: number;
  // DEV NOTE: selected shapes and images are based on the instances
  // since there can be multiple instances, this should probably be tracked inside the entity state,
  // BUT since we are not sure on the usage conop, this is a simpler cheat...but realize that things may need to change
  // iSpySelectedShapes: string[];
  socketConnection: boolean;
  iSpyRemoteInProgress: boolean;
  iSpyRemoteAction: string;
  requestedShapeToSelected: null | string[];
}

const initialState: ExtendedEntityAdapterState = {
  selectedDetects: [],
  showAll: true,
  iSpyOpen: false,
  instanceId: null,
  iSpyVersion: null,
  image: null,
  viewportExtents: null,
  syncViewport: true,
  lastEventTimestamp: null,
  lastError: null,
  winRef: null,
  iSpyViewportCenter: null,
  iSpyViewportScale: 1,
  // iSpySelectedShapes: [],
  socketConnection: false,
  iSpyRemoteInProgress: false,
  iSpyRemoteAction: '',
  requestedShapeToSelected: null,
};

let iSpyInterface = null;
let setupFunction: Function | null = null;

export const iSpySlice = createSlice({
  name: 'iSpy',
  initialState: ispyAdapter.getInitialState(initialState),
  reducers: {
    instanceAdded: ispyAdapter.addOne,
    instanceRemoved: ispyAdapter.removeOne,
    // viewAllDetects(state, action) {
    //
    // },
    setupRemoteCallback(state, action) {
      // state.socketConnection = false;
      setupFunction = action.payload;
    },
    onSocketConnection(state, action) {
      state.socketConnection = action.payload;
    },
    launch(state, action) {
      state.winRef = iSpyOpenAction(state, action.payload);
      state.iSpyOpen = true;
    },
    // viewImage(state, action) {
    //   if (!checkSocketConnection(state)) {
    //     console.log('No iSpy Connection');
    //     return;
    //   }
    //   // payload { windowReference, url, imageId, feature, lat, lon }
    //   // const { imageId, url } = buildViewImageUrl(action.payload);
    //   // console.log('TODO: view image url ', url);
    //   //iSpyOpenAction(state, url);
    //   if (state.ids.length > 0) {
    //     state.iSpyOpen = true;
    //     state.winRef = action.payload.windowReference;
    //     centeriSpyViewport(state, action.payload);
    //   } else {
    //     console.log('no ispy open...need to wait');
    //   }
    //
    //   // testISpyRemoteAction(action.payload);
    // },
    // centerViewport(state, action) {
    //   if (!checkSocketConnection(state)) {
    //     console.log('No iSpy Connection');
    //     return;
    //   }
    //   centeriSpyViewport(state, action.payload);
    // },
    // drawAllDetects(state, action) {
    //   console.log(`draw all detects for instanceID ${state.instanceId}`);
    //   if (!checkSocketConnection(state)) {
    //     console.log('No iSpy Connection');
    //     return;
    //   }
    //   //const { imageId, url } = buildViewImageUrl(action.payload);
    //   // if (!state.iSpyOpen) {
    //   //   state.winRef = iSpyOpenAction(state, url);
    //   //   state.iSpyOpen = true;
    //   // }
    //   if (state.ids.length > 0) {
    //     drawAllDetects(state.instanceId, action.payload);
    //     centeriSpyViewport(state, action.payload);
    //   } else {
    //     console.log('no ispy open...need to wait...try again');
    //   }
    //   // testISpyRemoteAction(action.payload);
    // },
    onEvent(state, action) {
      console.log('TODO: iSpy event event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
    },
    onStatus(state, action) {
      //console.log('iSpy status event ', JSON.stringify(action));
      // state.lastEventTimestamp = new Date();
      // if (action.payload.instanceID) {
      //   iSpyInterface = action.payload.iSpyInterface;
      //   state.instanceId = action.payload.instanceID;
      //   state.iSpyVersion = action.payload.iSpyVersion;
      //   state.image = updateImage(action.payload);
      //   state.viewportExtents = action.payload.viewportExtents;
      //   if (action.payload.instanceID && !state.ids.includes(action.payload.instanceID)) {
      //     console.log(`status update adding instance to state ${action.payload.instanceID}`);
      //     const iData: InstanceData = {
      //       instanceId: action.payload.instanceID,
      //       lastEventTimestamp: new Date(),
      //       image: null,
      //       images: instanceImagesAdapter.getInitialState(),
      //       selectedShapes: [],
      //       shapes: [],
      //     };
      //     ispyAdapter.upsertOne(state, { id: action.payload.instanceID, data: iData });
      //   }
      // }
    },
    onConnection(state, action) {
      // console.log('iSpy connection event ', action);
      // state.lastEventTimestamp = new Date();
      // if (action.payload.instanceID) {
      //   iSpyInterface = action.payload.iSpyInterface;
      //   state.instanceId = action.payload.instanceID;
      //   state.iSpyVersion = action.payload.iSpyVersion;
      //   if (action.payload.instanceID && !state.ids.includes(action.payload.instanceID)) {
      //     console.log(`connection adding instance to state ${action.payload.instanceID}`);
      //     const iData: InstanceData = {
      //       instanceId: action.payload.instanceID,
      //       lastEventTimestamp: new Date(),
      //       image: null,
      //       images: instanceImagesAdapter.getInitialState(),
      //       selectedShapes: [],
      //       shapes: [],
      //     };
      //     ispyAdapter.upsertOne(state, { id: action.payload.instanceID, data: iData });
      //     // id: string;
      //     // images: string[];
      //     // image: ISpyImage | null;
      //     // selectedShapes: string[];
      //     // shapes: string[];
      //     // TODO--update other slice of state
      //     // const instanceImageData: InstanceImageData = {
      //     //   id: action.payload.instanceID,
      //     //   images: [],
      //     //   image: null,
      //     //   selectedShapes: [],
      //     //   shapes: [],
      //     // };
      //     // imagesAdapter.addOne(instanceImageData);
      //   }
      // } else {
      //   console.log('connection event with no instanceID');
      // }
    },
    onImageLoad(state, action) {
      // console.log('iSpy image load event ', JSON.stringify(action));
      // state.lastEventTimestamp = new Date();
      // const image = updateImage(action.payload);
      // const changes = {
      //   id: action.payload.instanceID,
      //   changes: {
      //     data: {
      //       instanceId: action.payload.instanceID,
      //       image,
      //       lastEventTimestamp: new Date(),
      //       selectedShapes: [],
      //       images: instanceImagesAdapter.getInitialState(),
      //       shapes: [],
      //     },
      //   },
      // };
      // ispyAdapter.updateOne(state, changes);
      // state.image = image;
    },
    onImageClicked(state, action) {
      console.log('iSpy image click event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
      //TODO: any on image click?
    },
    onViewportChanged(state, action) {
      console.log('iSpy viewport change event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
      state.viewportExtents = action.payload.viewportExtents;
      if (state.syncViewport) {
        console.log('sync viewport');
        try {
          state.iSpyViewportCenter = syncViewport(action.payload.viewportExtents);
          state.iSpyViewportScale = action.payload.scale;
        } catch (err) {
          console.log(`sync viewport err ${err}`);
        }
      }
    },
    onShapeCreated(state, action) {
      //console.log('iSpy onShapeCreated event ', JSON.stringify(action));
      // state.lastEventTimestamp = new Date();
    },
    onShapeUpdated(state, action) {
      console.log('iSpy onShapeUpdated event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
    },
    onShapeDeleted(state, action) {
      // console.log('iSpy onShapeDeleted event ', JSON.stringify(action));
      // state.lastEventTimestamp = new Date();
    },
    onShapeSelected(state, action) {
      console.log('iSpy onShapeSelected event ', JSON.stringify(action));
      // state.iSpySelectedShapes = action.payload.shapeIDs;
      // state.lastEventTimestamp = new Date();
      // const currentState = state.entities[action.payload.instanceID];
      // if (currentState) {
      //   const instanceDataChange = {
      //     ...currentState.data,
      //     lastEventTimestamp: new Date(),
      //     selectedShapes: action.payload.shapeIDs,
      //   };
      //   const changes = {
      //     id: action.payload.instanceID,
      //     changes: {
      //       id: action.payload.instanceID,
      //       data: instanceDataChange,
      //     },
      //   };
      //   ispyAdapter.updateOne(state, changes);
      // }
    },
    onDisconnection(state, action) {
      // console.log('iSpy onDisconnection event ', JSON.stringify(action));
      // if (state.ids.includes(action.payload.instanceID)) {
      //   console.log(`state contains ${action.payload.instanceID}...removing`);
      //   ispyAdapter.removeOne(state, action.payload.instanceID);
      //   if (state.ids && state.ids.length === 0) {
      //     console.log('no longer tracking an iSpy instances...clearing state');
      //     state.instanceId = null;
      //     state.iSpyVersion = null;
      //     iSpyInterface = null;
      //     state.winRef = null;
      //   }
      // }
      // state.lastEventTimestamp = new Date();
    },
    onSystemDisconnect(state, action) {
      console.log('iSpy onSystemDisconnect event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
      iSpyInterface = null;
      state.winRef = null;
    },
    onError(state, action) {
      console.log('iSpy onError event ', JSON.stringify(action));
      state.lastError = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(onConnection.fulfilled, (state, action) => {
      console.log('onConnection fulfilled');
      state.lastEventTimestamp = new Date();
      if (action.payload.instanceID) {
        iSpyInterface = action.payload.iSpyInterface;
        state.instanceId = action.payload.instanceID;
        state.iSpyVersion = action.payload.iSpyVersion;
        if (action.payload.instanceID && !state.ids.includes(action.payload.instanceID)) {
          console.log(`connection adding instance to state ${action.payload.instanceID}`);
          const iData: InstanceData = {
            instanceId: action.payload.instanceID,
            lastEventTimestamp: new Date(),
            image: null,
            images: instanceImagesAdapter.getInitialState(),
            selectedShapes: [],
            shapes: [],
          };
          ispyAdapter.upsertOne(state, { id: action.payload.instanceID, data: iData });
        }
      } else {
        console.log('connection event with no instanceID');
      }
    });
    builder.addCase(onDisconnection.fulfilled, (state, action) => {
      console.log('onDisconnection fulfilled');
      console.log('iSpy onDisconnection event ', JSON.stringify(action));
      if (state.ids.includes(action.payload.instanceID)) {
        console.log(`state contains ${action.payload.instanceID}...removing`);
        ispyAdapter.removeOne(state, action.payload.instanceID);
        if (state.ids && state.ids.length === 0) {
          console.log('no longer tracking an iSpy instances...clearing state');
          state.instanceId = null;
          state.iSpyVersion = null;
          state.image = null;
          iSpyInterface = null;
          state.winRef = null;
          state.viewportExtents = null;
          state.iSpyViewportCenter = null;
          // state.iSpySelectedShapes = [];
          state.iSpyOpen = false;
          state.iSpyRemoteAction = '';
          state.iSpyRemoteInProgress = false;
        }
      }
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(onStatus.fulfilled, (state, action) => {
      console.log('onStatus fulfilled');
      state.lastEventTimestamp = new Date();
      if (action.payload.instanceID) {
        iSpyInterface = action.payload.iSpyInterface;
        state.instanceId = action.payload.instanceID;
        state.iSpyVersion = action.payload.iSpyVersion;
        state.image = updateImage(action.payload);
        state.viewportExtents = action.payload.viewportExtents;
        if (action.payload.instanceID && !state.ids.includes(action.payload.instanceID)) {
          console.log(`status update adding instance to state ${action.payload.instanceID}`);
          const iData: InstanceData = {
            instanceId: action.payload.instanceID,
            lastEventTimestamp: new Date(),
            image: null,
            images: instanceImagesAdapter.getInitialState(),
            selectedShapes: [],
            shapes: [],
          };
          ispyAdapter.upsertOne(state, { id: action.payload.instanceID, data: iData });
        }
      }
    });
    builder.addCase(onStatus.rejected, (state, action) => {
      console.log('onStatus rejected');
    });
    builder.addCase(onShapeCreated.pending, (state, action) => {
      //console.log('onShape Created fulfilled');
    });
    builder.addCase(onShapeCreated.fulfilled, (state, action) => {
      //console.log('onShape Created fulfilled');
      state.iSpyRemoteAction = 'Detect Created';
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(onShapeCreated.rejected, (state, action) => {
      //console.log('onShape Created fulfilled');
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(onShapeDeleted.fulfilled, (state, action) => {
      console.log('onShapeDeleted fulfilled');
      state.iSpyRemoteAction = 'Detect Deleted';
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(onShapeSelected.fulfilled, (state, action) => {
      console.log('onShapeSelected fulfilled');
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(selectShapes.pending, (state, action) => {
      console.log(`selectShapes pending ${JSON.stringify(action.meta)}`);
      state.requestedShapeToSelected = action.meta.arg.shapeIDs;
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(selectShapes.fulfilled, (state, action) => {
      console.log('selectShapes fulfilled');
      state.requestedShapeToSelected = null;
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(selectShapes.rejected, (state, action) => {
      console.log('selectShapes rejected');
      state.requestedShapeToSelected = null;
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(addImage.pending, (state, action) => {
      console.log('addImage pending');
      state.iSpyRemoteInProgress = true;
      state.iSpyRemoteAction = 'Processing Image';
    });
    builder.addCase(addImage.fulfilled, (state, action) => {
      console.log('addImage fulfilled');
      state.iSpyRemoteInProgress = false;
      state.iSpyRemoteAction = '';
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(addImage.rejected, (state, action) => {
      console.log('addImage rejected');
      state.iSpyRemoteInProgress = false;
      state.iSpyRemoteAction = '';
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(viewDetect.pending, (state, action) => {
      console.log('viewDetect pending');
      state.iSpyRemoteInProgress = true;
      state.iSpyRemoteAction = 'Processing Detect';
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(viewDetect.fulfilled, (state, action) => {
      console.log('viewDetect fulfilled');
      state.iSpyRemoteInProgress = false;
      state.iSpyRemoteAction = '';
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(viewDetect.rejected, (state, action) => {
      console.log('viewDetect rejected');
      state.iSpyRemoteInProgress = false;
      state.iSpyRemoteAction = '';
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(viewAllDetects.pending, (state, action) => {
      console.log('viewAllDetects pending');
      state.iSpyRemoteInProgress = true;
      state.iSpyRemoteAction = 'Processing Detects';
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(viewAllDetects.fulfilled, (state, action) => {
      console.log('viewAllDetects fulfilled');
      state.iSpyRemoteInProgress = false;
      state.iSpyRemoteAction = '';
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(viewAllDetects.rejected, (state, action) => {
      console.log('viewAllDetects rejected');
      state.iSpyRemoteInProgress = false;
      state.iSpyRemoteAction = '';
      state.lastEventTimestamp = new Date();
    });
    builder.addCase(cancelRemoteAction.fulfilled, (state, action) => {
      console.log('cancelRemoteAction fulfilled');
      state.iSpyRemoteInProgress = false;
      state.iSpyRemoteAction = '';
      state.lastEventTimestamp = new Date();
    });
    // builder.addCase(onImageLoad.fulfilled, (state, action) => {
    //   console.log('onImageLoad fulfilled');
    //   state.iSpyRemoteInProgress = false;
    //   console.log('iSpy image load event ', JSON.stringify(action));
    //   state.lastEventTimestamp = new Date();
    //   const image = updateImage(action.payload);
    //   const changes = {
    //     id: action.payload.instanceID,
    //     changes: {
    //       data: {
    //         instanceId: action.payload.instanceID,
    //         image,
    //         lastEventTimestamp: new Date(),
    //         selectedShapes: [],
    //         images: instanceImagesAdapter.getInitialState(),
    //         shapes: [],
    //       },
    //     },
    //   };
    //   ispyAdapter.updateOne(state, changes);
    //   state.image = image;
    // });
  },
});

const checkSocketConnection = state => {
  if (!state.socketConnection || iSpyInterface === null) {
    if (setupFunction) {
      console.log('checkSocketConnection calling setupFunction');
      setupFunction();
    }
  }
  return state.socketConnection;
};

const waitForiSpy = async () => {
  // @ts-ignore
  if (!iSpyInterface) {
    return await new Promise(resolve => setTimeout(resolve, 1000));
  } else {
    return Promise.resolve();
  }
};

const centeriSpyViewport = async (state, payload) => {
  if (payload.lat && payload.lon) {
    // @ts-ignore
    return await iSpyInterface.centerViewport(payload.instanceId, payload.imageId, payload.lat, payload.lon, 1);
  } else {
    return Promise.reject('Unable to center viewport. Coordinates missing');
  }
};

const deleteAllDetectShapes = state => {
  // if (!checkSocketConnection(state)) {
  //   console.log('No iSpy Connection');
  //   return;
  // }
  // const shapesToDelete = selectShapesByInstance2(state, state.instanceId);
  const shapesToDelete = selectShapesByInstance(state, state.instanceId);
  console.log(`shape to delete results ${shapesToDelete}`);
  if (shapesToDelete.length) {
    // @ts-ignore
    iSpyInterface.deleteShapes(shapesToDelete, state.instanceId);
  }
};

const updateImage = imagePayload => {
  const image: ISpyImage = {
    imageID: imagePayload.imageID,
    imageDate: imagePayload.imageDate,
    imagePath: imagePayload.imagePath,
    imageSecurity: imagePayload.imageSecurity,
    imageExtents: imagePayload.imageExtents,
    imageGuide: imagePayload.imageGuide,
  };
  return image;
};

const buildViewImageUrl = (payload: AtrDetectViewImageType) => {
  const imageId = payload.feature.properties['Image Location'];
  //lat=12.234&lon=98.765
  // const url = `${payload.url}/image?source_select=true&imageid=${image}`;
  let url = `${payload.url}?remote=true&source_select=true&imageid=${imageId}`;
  if (payload.lat && payload.lon) {
    url = `${url}&lat=${payload.lat}&lon=${payload.lon}`;
  } else {
    url = `${url}&fit=true`;
  }
  const data: ImageUrlData = { imageId, url };
  return data;
};

const splitEvery = (n, xs, y: any = []) => {
  return xs.length === 0 ? y : splitEvery(n, xs.slice(n), y.concat([xs.slice(0, n)]));
};

const drawDetect = async (instanceID, payload: DetectRequest) => {
  const feature = payload.feature;
  console.log(`draw detect ${JSON.stringify(feature)}`);
  const requestPayload = buildShapeRequestPayload(instanceID, payload.imageId, feature.id, feature, payload.color);
  console.log(`draw detect request  ${JSON.stringify(requestPayload)}`);
  // @ts-ignore
  return iSpyInterface.drawShape(requestPayload);
};

const drawAllDetects = async (instanceID, payload: DetectRequest) => {
  console.log('drawAllDetects entry');
  let batchSize = -1;
  if (payload.batchSize && payload.batchSize > 0) {
    batchSize = payload.batchSize;
  }
  const feature = payload.feature;
  const atrDetects = payload.atrDetectEntities;
  const atrShapes = atrDetects?.map(entity => {
    return buildShape(instanceID, entity.id, entity.data, payload.color);
  });
  //batch
  let chunks = [atrShapes];
  if (batchSize > 0) {
    chunks = splitEvery(batchSize, atrShapes);
  }

  // async function chainPromiseCalls(asyncFunctions=[],respectiveParams=[]){
  //
  //   for(let i=0;i<asyncFunctions.length;i++){
  //     const eachResult = await asyncFunctions[i](...respectiveParams[i]);
  //     // do what you want to do with each result
  //
  //   }
  //
  //   return ;
  // }

  console.log(`draw all detects based on  ${JSON.stringify(feature)}`);
  console.log(`draw all detects number of shapes  ${atrShapes?.length}`);
  // get all detects, convert to shapes, bundle, send
  if (chunks && chunks.length > 0) {
    for (let i = 0; i < chunks.length; i++) {
      const requestPayload = buildShapesRequestPayload(instanceID, payload.imageId, chunks[i]);
      console.log(`draw all detect request  ${JSON.stringify(requestPayload)}`);
      // @ts-ignore
      await iSpyInterface.drawShape(requestPayload);
    }
    // chunks.forEach(batch => {
    //   const requestPayload = buildShapesRequestPayload(instanceID, payload.imageId, batch);
    //   console.log(`draw all detect request  ${JSON.stringify(requestPayload)}`);
    //   // @ts-ignore
    //   // await iSpyInterface.drawShape(requestPayload);
    // });
  }
  // @ts-ignore
  //   await iSpyInterface.drawShape({});
};

const iSpyOpenAction = (state, url) => {
  let wRef;
  if (state.winRef) {
    console.log('Window already open...focus');
    wRef = state.winRef;
    state.winRef.focus();
    return wRef;
  }
  if (url) {
    //wRef = window.open(url, '_blank', 'noopener, noreferrer');
    wRef = window.open(url, 'sgs-ispy', 'noreferrer');
  }
  return wRef;
};

const buildShapeRequestPayload = (instanceID, imageID, shapeID, feature, color = 'yellow') => {
  const vertices = feature.geometry.coordinates;
  const name = getFuzzyPropertyData(feature.properties, 'classificationName') || 'Unknown';
  const shapePayload = {
    // instanceID: instanceID,
    imageID: imageID,
    shapes: [
      {
        type: 'Feature',
        id: shapeID,
        properties: {
          color: color,
          metadata: {
            title: name,
            date: isoToEpochMilliseconds(feature.properties['Created Time']),
            description: 'Shadow Gunslinger ATR Detect',
            customMetadata: {
              detect: name,
              confidence: getFuzzyPropertyData(feature.properties, 'confidence') || 'Unknown',
              verification: getFuzzyPropertyData(feature.properties, 'Verification') || 'Unknown',
              model: feature.properties.Model,
              source: 'SGS',
              detectId: shapeID,
            },
          },
          allowEditing: false,
        },
        geometry: {
          type: 'Polygon',
          coordinates: vertices,
        },
      },
    ],
    select: true,
  };
  return shapePayload;
};

const isoToEpochMilliseconds = isoDate => {
  try {
    const date = new Date(isoDate);
    return date.getTime();
  } catch (oops) {
    return Date.now();
  }
};

const buildShapesRequestPayload = (instanceID, imageID, shapes) => {
  const shapePayload = {
    instanceID: instanceID,
    imageID: imageID,
    shapes: shapes,
    select: false,
  };
  return shapePayload;
};

const buildShape = (instanceID, shapeID, feature, color = 'yellow') => {
  const vertices = feature.geometry.coordinates;
  const name = getFuzzyPropertyData(feature.properties, 'classificationName') || 'Unknown';
  return {
    type: 'Feature',
    id: shapeID,
    properties: {
      color: color,
      metadata: {
        title: name,
        date: isoToEpochMilliseconds(feature.properties['Created Time']),
        description: 'Shadow Gunslinger ATR Detect',
        customMetadata: {
          detect: name,
          confidence: getFuzzyPropertyData(feature.properties, 'confidence') || 'Unknown',
          verification: getFuzzyPropertyData(feature.properties, 'Verification') || 'Unknown',
          model: feature.properties.Model,
          source: 'SGS',
          detectId: shapeID,
        },
      },
      allowEditing: false,
    },
    geometry: {
      type: 'Polygon',
      coordinates: vertices,
    },
  };
};

const getMiddle = (prop, markers) => {
  let values = markers.map(m => m[prop]);
  let min = Math.min(...values);
  let max = Math.max(...values);
  if (prop === 'lon' && max - min > 180) {
    values = values.map(val => (val < max - 180 ? val + 360 : val));
    min = Math.min(...values);
    max = Math.max(...values);
  }
  let result = (min + max) / 2;
  if (prop === 'lon' && result > 180) {
    result -= 360;
  }
  return result;
};

const findCenter = markers => {
  return {
    lat: getMiddle('lat', markers),
    lon: getMiddle('lon', markers),
  };
};

// Sync the sgs viewport with the iSpy viewport
// 20250124 -- not operational yet...experimental code to track connection events and debug
const syncViewport = viewportExtents => {
  if (viewportExtents) {
    const ul = viewportExtents.upperLeft;
    const ulm = { lat: ul.lat, lon: ul.lon };
    const ur = viewportExtents.upperRight;
    const urm = { lat: ur.lat, lon: ur.lon };
    const bl = viewportExtents.bottomLeft;
    const blm = { lat: bl.lat, lon: bl.lon };
    const br = viewportExtents.bottomRight;
    const brm = { lat: br.lat, lon: br.lon };
    const boundingBox = [ulm, urm, blm, brm];
    const centerOfBoundingBox = findCenter(boundingBox);
    console.log(`syncViewport centerOfBoundingBox ${JSON.stringify(centerOfBoundingBox)}`);
    return centerOfBoundingBox;
  }
  return null;
};

const testISpyRemoteAction = payload => {
  try {
    if (payload.lat && payload.lon) {
      // @ts-ignore
      iSpyInterface.centerViewport(payload.lat, payload.lon, 1.0);
    } else {
      // @ts-ignore
      iSpyInterface.centerViewport(39, -105, 1.0);
    }
  } catch (err) {
    console.log(`Error sending iSpy command ${err}`);
  }
};

/**
 * Thunk to update based on events
 */

export const onConnection = createAsyncThunk('ispy/onConnection', async (payload: any, thunkAPI) => {
  try {
    console.log(`onConnection thunk entry ${payload}`);
    thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onConnection(payload));
    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`onConnection error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const onDisconnection = createAsyncThunk('ispy/onDisconnection', async (payload: any, thunkAPI) => {
  try {
    console.log(`onDisconnection thunk entry ${payload}`);
    thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onDisconnection(payload));
    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`onDisconnection error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const onStatus = createAsyncThunk('ispy/onStatus', async (payload: any, thunkAPI) => {
  try {
    console.log(`onStatus thunk entry ${payload}`);
    const id = payload.instanceID;
    iSpyInterface = payload.iSpyInterface;

    if (iSpyInterface) {
      // @ts-ignore
      const shapes = await iSpyInterface.getShapes(id);
      console.log(`get shapes results ${JSON.stringify(shapes)}`);
      payload.shapeData = shapes;
    } else {
      console.warn('iSpyInterface is null, unable to request shape data');
    }
    thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onStatus(payload));
    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`onsStatus error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const onImageLoad = createAsyncThunk('ispy/onImageLoad', async (payload: any, thunkAPI) => {
  try {
    console.log(`onImageLoad thunk entry`);
    try {
      // @ts-ignore
      const shapes = await iSpyInterface.getShapes(payload.instanceID, payload.imageID);
      console.log(`onImageLoad -- get shapes results ${JSON.stringify(shapes)}`);
    } catch (shapesError) {
      console.error(`Unable to get image shapes ${shapesError}`);
    }
    thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onImageLoad(payload));
    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`onImageLoad error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const onImageUnload = createAsyncThunk('ispy/onImageUnload', async (payload: any, thunkAPI) => {
  try {
    console.log(`onImageUnload thunk entry ${payload}`);
    thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onImageUnload(payload));
    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`onImageUnload error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const onShapeSelected = createAsyncThunk('ispy/onShapeSelected', async (payload: any, thunkAPI) => {
  try {
    console.log(`onShapeSelected thunk entry ${JSON.stringify(payload)}`);
    // const currentState: any = await thunkAPI.getState();
    // if (currentState) {
    //   const appRequestedSelectedShapes = currentState.iSpy.requestedShapeToSelected;
    //   if (appRequestedSelectedShapes && appRequestedSelectedShapes.length > 0) {
    //     console.log(`app requested to select ${JSON.stringify(appRequestedSelectedShapes)}`);
    //     const iSpySelectedShapes = payload.shapeIDs;
    //     const found = appRequestedSelectedShapes.some(r=> iSpySelectedShapes.includes(r));
    //     if (found) {
    //       console.log('shape selection via ');
    //       return payload;
    //     }
    //   }
    // }
    thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onShapeSelected(payload));
    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`onShapeSelected error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const onShapeCreated = createAsyncThunk('ispy/onShapeCreated', async (payload: any, thunkAPI) => {
  try {
    // console.log(`onShapeCreated thunk entry ${payload}`);
    thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onShapeCreated(payload));
    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`onShapeCreated error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const onShapeDeleted = createAsyncThunk('ispy/onShapeDeleted', async (payload: any, thunkAPI) => {
  try {
    console.log(`onShapeDeleted thunk entry ${payload}`);
    thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onShapeDeleted(payload));
    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`onShapeDeleted error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const selectShapes = createAsyncThunk('ispy/selectShapes', async (payload: any, thunkAPI) => {
  try {
    console.log(`selectShapes thunk entry ${payload}`);
    // @ts-ignore
    if (!checkSocketConnection(thunkAPI.getState().iSpy)) {
      console.log('No iSpy Connection');
      return thunkAPI.rejectWithValue('No iSpy connection available');
    }
    const currentState: any = await thunkAPI.getState();
    if (currentState) {
      const id = currentState.iSpy.instanceId;
      const shapesToSelect = payload.shapeIDs;
      const currentlySelectShapes = selectiSpySelectedShapes(currentState);
      const result = currentlySelectShapes.some(shapeId => shapesToSelect.includes(shapeId));
      if (result) {
        console.log('shape already selected, nothing to do');
        return payload;
      }
      // @ts-ignore
      const selectResults = await iSpyInterface.selectShapes(shapesToSelect, id);
      console.log(`selectShapes results ${JSON.stringify(selectResults)}`);
    }
    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`selectShapes error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const deleteAllShapes = createAsyncThunk('ispy/deleteAllShapes', async (payload: any, thunkAPI) => {
  try {
    console.log(`deleteAllShapes thunk entry ${payload}`);

    let count = 0;
    while (!iSpyInterface && count < ISPY_INTERFACE_AVAILABLE_RETRY) {
      console.log(`waiting for ispyinterface...`);
      await waitForiSpy();
      count++;
    }
    // @ts-ignore
    if (!checkSocketConnection(thunkAPI.getState().iSpy)) {
      console.log('No iSpy Connection');
      return thunkAPI.rejectWithValue('No iSpy connection available');
    }
    // @ts-ignore
    const currentState: any = await thunkAPI.getState();
    if (currentState) {
      const id = currentState.iSpy.instanceId;
      const atrIds = currentState.atr.ids;
      console.log(`shape to delete atr ids ${JSON.stringify(atrIds)}`);
      // const deletePayload = { instanceId: id, atrIds };
      // const shapesToDelete = selectShapesByInstance(currentState, id);
      const shapesToDelete = selectShapesByLoadedImage(currentState, id);
      console.log(`shapes to delete for loaded image results ${JSON.stringify(shapesToDelete)}`);
      if (shapesToDelete.length) {
        // @ts-ignore
        const deleteResults = await iSpyInterface.deleteShapes(shapesToDelete, id);
        thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onShapeDeleted(deleteResults));
      }
      // thunkAPI.dispatch(iSpyInstanceImageSlice.actions.deleteAllShapes(id));
    }

    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`deleteAllShapes error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const addImage = createAsyncThunk('ispy/addImage', async (payload: any, thunkAPI) => {
  try {
    console.log(`addImage thunk entry ${JSON.stringify(payload)}`);
    // @ts-ignore
    if (!checkSocketConnection(thunkAPI.getState().iSpy)) {
      console.log('No iSpy Connection');
      return thunkAPI.rejectWithValue('No iSpy connection available');
    }
    let count = 0;
    let retryCount = ISPY_INTERFACE_AVAILABLE_RETRY;
    // @ts-ignore
    if (!thunkAPI.getState().iSpy.instanceId) {
      console.log('increasing retry count due to instance not yet available');
      retryCount = retryCount * 2;
    }
    while (!iSpyInterface && count < retryCount) {
      console.log(`waiting for ispyinterface...`);
      await waitForiSpy();
      count++;
    }

    if (!iSpyInterface) {
      return thunkAPI.rejectWithValue('No iSpy connection available');
    }
    // @ts-ignore
    const currentState: any = await thunkAPI.getState();
    if (currentState) {
      const id = currentState.iSpy.instanceId;
      console.log(`currentState.iSpy.instanceId ${JSON.stringify(id)}`);
      const instanceImages = selectLoadedImagesByInstance(currentState, id);
      // const imageIdFound = instanceImages.some(images => images.includes(payload.imageId));
      const imageIdFound = instanceImages.includes(payload.imageId);
      console.log(`imageIdFound ${imageIdFound}`);
      if (!imageIdFound) {
        console.log(`image ${payload.imageId} not  loaded`);
        if (payload.clearDetectsOnImageChange) {
          console.log('clearDetectsOnImageChange');
          try {
            const shapesToDelete = selectShapesByLoadedImage(currentState, id);
            console.log(`shapes to delete for loaded image results ${JSON.stringify(shapesToDelete)}`);
            if (shapesToDelete.length) {
              // @ts-ignore
              const deleteResults = await iSpyInterface.deleteShapes(shapesToDelete, id);
              thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onShapeDeleted(deleteResults));
            }
          } catch (deleteError) {
            console.warn('Unable to delete existing shapes');
          }
        }
        const imageIDS = [payload.imageId];
        console.log(`loaded images results ${JSON.stringify(instanceImages)}`);
        let result = null;
        const forceLoad = LOAD_IMAGE_ON_ADD && instanceImages.length > 1;
        if (LOAD_IMAGE_ON_ADD) {
          // @ts-ignore
          result = await iSpyInterface.loadImages(imageIDS, id);
          console.log(`loadImage thunk result ${JSON.stringify(result)}`);
        } else {
          // @ts-ignore
          result = await iSpyInterface.addImages(imageIDS, id);
          console.log(`addImage thunk result ${JSON.stringify(result)}`);
        }
      }

      // @ts-ignore
      const centerResult = await iSpyInterface.centerViewport(id, payload.imageId, payload.lat, payload.lon, 1);
      console.log(`add image centerResult thunk result ${JSON.stringify(centerResult)}`);
    }

    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`addImage error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

// { imageId, url, feature, color, lat, lon }
export const viewDetect = createAsyncThunk('ispy/viewDetect', async (payload: any, thunkAPI) => {
  try {
    console.log(`viewDetect thunk entry ${JSON.stringify(payload)}`);
    let count = 0;
    let retryCount = ISPY_INTERFACE_AVAILABLE_RETRY;
    // @ts-ignore
    if (!thunkAPI.getState().iSpy.instanceId) {
      console.log('increasing retry count due to instance not yet available');
      retryCount = retryCount * 2;
    }
    while (!iSpyInterface && count < retryCount) {
      console.log(`waiting for ispyinterface...`);
      await waitForiSpy();
      count++;
    }
    if (!iSpyInterface) {
      return thunkAPI.rejectWithValue('No iSpy connection available');
    }
    // @ts-ignore
    if (!checkSocketConnection(thunkAPI.getState().iSpy)) {
      console.log('No iSpy Connection');
      return thunkAPI.rejectWithValue('No iSpy connection available');
    }
    // @ts-ignore
    const currentState: any = await thunkAPI.getState();
    if (currentState) {
      const id = currentState.iSpy.instanceId;
      console.log(`currentState.iSpy.instanceId ${JSON.stringify(id)}`);
      const instanceImages = selectLoadedImagesByInstance(currentState, id);
      console.log(`loaded images results ${JSON.stringify(instanceImages)}`);
      // const imageIdFound = instanceImages.some(images => images.includes(payload.imageId));
      const imageIdFound = instanceImages.includes(payload.imageId);
      console.log(`imageIdFound ${imageIdFound}`);
      const forceLoad = LOAD_IMAGE_ON_ADD && instanceImages.length > 1;
      //check if image is loaded, if not, load
      if (!imageIdFound || forceLoad) {
        console.log(`detect image ${payload.imageId} not loaded, force load ${forceLoad}`);
        if (payload.clearDetectsOnImageChange) {
          console.log('clearDetectsOnImageChange');
          try {
            const shapesToDelete = selectShapesByLoadedImage(currentState, id);
            console.log(`shapes to delete for loaded image results ${JSON.stringify(shapesToDelete)}`);
            if (shapesToDelete.length) {
              // @ts-ignore
              const deleteResults = await iSpyInterface.deleteShapes(shapesToDelete, id);
              thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onShapeDeleted(deleteResults));
            }
          } catch (deleteError) {
            console.warn('Unable to delete existing shapes');
          }
        }
        const imageIDS = [payload.imageId];
        let result = null;
        if (LOAD_IMAGE_ON_ADD) {
          // @ts-ignore
          result = await iSpyInterface.loadImages(imageIDS, id);
          console.log(`viewDetect loadImage thunk result ${JSON.stringify(result)}`);
        } else {
          // @ts-ignore
          result = await iSpyInterface.addImages(imageIDS, id);
          console.log(`viewDetect addImage thunk result ${JSON.stringify(result)}`);
        }
      }

      const detectsForImageId = selectDetectsByImageId(currentState, payload.imageId);
      const existingShapes = selectShapesByInstanceAndImage(currentState, id, payload.imageId);

      // const detectsToDraw = detectsForImageId.filter(detect => {
      //   return detect ? !existingShapes.includes(detect.id) : false;
      // });
      console.log(`detects to draw for image ${JSON.stringify(detectsForImageId)}`);
      console.log(`detects to draw existingShapes ${JSON.stringify(existingShapes)}`);
      // console.log(`filtered detects to draw (${detectsToDraw.length}) detectsToDraw  ${JSON.stringify(detectsToDraw)}`);

      const detectAlreadyExists = existingShapes.includes(payload.feature.id);
      console.log(`detectAlreadyExists for ${payload.feature.id}: ${detectAlreadyExists}`);
      if (!detectAlreadyExists) {
        console.log('viewDetect -- calling drawDetect');
        // payload.atrDetectEntities = [payload.feature];
        // await drawDetect(id, payload);
        await drawDetect(id, payload);
        // await drawAllDetects(id, payload);
      }

      // @ts-ignore
      const centerResult = await iSpyInterface.centerViewport(id, payload.imageId, payload.lat, payload.lon, 1);
      console.log(`viewDetect centerResult thunk result ${JSON.stringify(centerResult)}`);
    }

    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`viewDetect error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

// { imageId, url, feature, color, lat, lon, atrDetectEntities, batchSize }
export const viewAllDetects = createAsyncThunk('ispy/viewAllDetects', async (payload: any, thunkAPI) => {
  try {
    console.log(`viewAllDetects thunk entry ${JSON.stringify(payload)}`);
    let count = 0;
    let retryCount = ISPY_INTERFACE_AVAILABLE_RETRY;
    // @ts-ignore
    if (!thunkAPI.getState().iSpy.instanceId) {
      console.log('increasing retry count due to instance not yet available');
      retryCount = retryCount * 2;
    }
    while (!iSpyInterface && count < retryCount) {
      console.log(`waiting for ispyinterface...`);
      await waitForiSpy();
      count++;
    }
    if (!iSpyInterface) {
      return thunkAPI.rejectWithValue('No iSpy connection available');
    }
    // @ts-ignore
    if (!checkSocketConnection(thunkAPI.getState().iSpy)) {
      console.log('No iSpy Connection');
      return thunkAPI.rejectWithValue('No iSpy connection available');
    }
    // @ts-ignore
    const currentState: any = await thunkAPI.getState();
    if (currentState) {
      const id = currentState.iSpy.instanceId;
      console.log(`currentState.iSpy.instanceId ${JSON.stringify(id)}`);
      const instanceImages = selectLoadedImagesByInstance(currentState, id);
      console.log(`loaded images results ${JSON.stringify(instanceImages)}`);
      // const imageIdFound = instanceImages.some(images => images.includes(payload.imageId));
      const imageIdFound = instanceImages.includes(payload.imageId);
      console.log(`imageIdFound ${imageIdFound}`);
      const forceLoad = LOAD_IMAGE_ON_ADD && instanceImages.length > 1;
      //check if image is loaded, if not, load
      if (!imageIdFound || forceLoad) {
        console.log(`detect image ${payload.imageId} not loaded, force load ${forceLoad}`);
        if (payload.clearDetectsOnImageChange) {
          console.log('clearDetectsOnImageChange');
          try {
            const shapesToDelete = selectShapesByLoadedImage(currentState, id);
            console.log(`shapes to delete for loaded image results ${JSON.stringify(shapesToDelete)}`);
            if (shapesToDelete.length) {
              // @ts-ignore
              const deleteResults = await iSpyInterface.deleteShapes(shapesToDelete, id);
              thunkAPI.dispatch(iSpyInstanceImageSlice.actions.onShapeDeleted(deleteResults));
            }
          } catch (deleteError) {
            console.warn('Unable to delete existing shapes');
          }
        }
        const imageIDS = [payload.imageId];
        let result = null;
        if (LOAD_IMAGE_ON_ADD) {
          // @ts-ignore
          result = await iSpyInterface.loadImages(imageIDS, id);
          console.log(`viewAllDetects loadImage thunk result ${JSON.stringify(result)}`);
        } else {
          // @ts-ignore
          result = await iSpyInterface.addImages(imageIDS, id);
          console.log(`viewAllDetects addImage thunk result ${JSON.stringify(result)}`);
        }
      }
      // const detectsForImage = selectAtrIdsByImageId(currentState, payload.imageId);
      const detectsForImageId = selectDetectsByImageId(currentState, payload.imageId);
      const existingShapes = selectShapesByInstanceAndImage(currentState, id, payload.imageId);

      const detectsToDraw = detectsForImageId.filter(detect => {
        return detect ? !existingShapes.includes(detect.id) : false;
      });
      console.log(`detects to draw for image ${JSON.stringify(detectsForImageId)}`);
      console.log(`detects to draw existingShapes ${JSON.stringify(existingShapes)}`);
      console.log(`filtered detects to draw (${detectsToDraw.length}) detectsToDraw  ${JSON.stringify(detectsToDraw)}`);
      if (detectsToDraw.length > 0) {
        payload.atrDetectEntities = detectsToDraw;
        await drawAllDetects(id, payload);
      }
      try {
        const geoCoords = payload.feature.geometry.coordinates[0][0];
        console.log(`centering detects on ${JSON.stringify(geoCoords)}`);
        //@ts-ignore
        const centerResult = await iSpyInterface.centerViewport(id, payload.imageId, geoCoords[1], geoCoords[0], 0.25);
        console.log(`viewAllDetects centerResult thunk result ${JSON.stringify(centerResult)}`);
      } catch (centerError) {
        console.log(`Unable to center viewport ${centerError}`);
      }
    }

    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`viewAllDetects error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

// 20250221 -- need to decide if this is by instance or by image...or both based on params
// right now it is by instance
export const getAllShapes = createAsyncThunk('ispy/getAllShapes', async (payload: any, thunkAPI) => {
  try {
    console.log(`getAllShapes thunk entry ${payload}`);
    // @ts-ignore
    if (!checkSocketConnection(thunkAPI.getState().iSpy)) {
      console.log('No iSpy Connection');
      return;
    }
    // @ts-ignore
    const currentState: any = await thunkAPI.getState();
    if (currentState) {
      const id = currentState.iSpy.instanceId;
      // @ts-ignore
      const shapes = await iSpyInterface.getShapes(id);
      console.log(`get shapes results ${JSON.stringify(shapes)}`);

      // thunkAPI.dispatch(iSpyInstanceImageSlice.actions.deleteAllShapes(id));
    }

    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`getAllShapes error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

/*
Really getting into som sage/orch middleware need here...this cancel is really just to reset the remote progress action state.
Made a sync  thunk in case ispy dev get back to me with some cancel operations, but I don't think they exist att.
Being that this is supposed to be a temp integration, I'm going with this for now.
 */
export const cancelRemoteAction = createAsyncThunk('ispy/cancelRemoteAction', async (payload: any, thunkAPI) => {
  try {
    console.log(`cancelRemoteAction thunk entry ${payload}`);
    return payload;
  } catch (ex) {
    //@ts-ignore
    console.error(`cancelRemoteAction error ${ex}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

const entitiesState = state => state.iSpy.entities;
const iSpyWindowReferenceState = state => state.iSpy.winRef;
const iSpyViewportCenterState = state => state.iSpy.iSpyViewportCenter;
const iSpyViewportScaleState = state => state.iSpy.iSpyViewportScale;
const iSpyInstanceState = state => state.iSpy.instanceId;
const iSpyRemoteProgressState = state => state.iSpy.iSpyRemoteInProgress;
const iSpyRemoteAction = state => state.iSpy.iSpyRemoteAction;
const iSpyLastEventTimestamp = state => state.iSpy.lastEventTimestamp;
export const selectiSpyViewportCenter = createSelector(
  iSpyViewportCenterState,
  iSpyViewportScaleState,
  (center, scale) => {
    return { center, scale };
  },
);
export const selectiSpyWindowReference = createSelector([iSpyWindowReferenceState], x => x);
export const selectiSpyDisconnected = createSelector([entitiesState], x => x == null || x.length === 0);
export const selectiSpyInstanceId = createSelector([iSpyInstanceState], x => x);
export const selectiSpyRemoteProgressState = createSelector([iSpyRemoteProgressState], x => x);
export const selectiSpyRemoteActionState = createSelector([iSpyRemoteAction], x => x);
export const selectiSpyLastEventTimestamp = createSelector([iSpyLastEventTimestamp], x => x);

const getShapeReducer = (deletePayload: any) => {
  const reducer = (acc: string[], curr: InstanceImageData) => {
    if (curr.instanceId === deletePayload.instanceId) {
      const atrIds = deletePayload.atrIds;
      console.log('InstanceImageData reducer instanceid match');
      console.log(`InstanceImageData curr.shapes ${JSON.stringify(curr.shapes)}`);
      const justAtrShapes = curr.shapes.filter(val => atrIds.includes(val));
      acc.push(...justAtrShapes);
      console.log(`InstanceImageData reducer accum ${JSON.stringify(acc)}`);
      return acc;
    }
    return acc;
  };
  return reducer;
};

// const instanceImageEntities = state => selectAll(state);
//
// export const selectShapesByInstance2 = createSelector(
//   [
//     // Usual first input - extract value from `state`
//     instanceImageEntities,
//     // Take the second arg, `entry parameters`, and forward to the output selector
//     (state, deletePayload) => deletePayload,
//   ],
//   // Output selector gets (`items, entry)` as args
//   (entities, deletePayload) => {
//     // const instanceId: string = deletePayload.instanceId;
//     // const atrIds: string[] = deletePayload.atrIds;
//     console.log(`instance entities size ${entities.length}`);
//     const reducer = getShapeReducer(deletePayload);
//     // @ts-ignore
//     const result: string[] = entities.reduce(reducer, []);
//     return result;
//   },
// );

// const selectABC2 = createSelector(selectA, selectB, selectC, (a, b, c) => {
//   // do something with a, b, and c, and return a result
//   return a + b + c
// })

const { launch } = iSpySlice.actions;

export const {
  selectById: selectiSpyById,
  selectIds: selectiSpyIds,
  selectEntities: selectiSpyEntities,
  selectAll: selectAllInstances,
  selectTotal: selectTotalInstances,
} = ispyAdapter.getSelectors((state: any) => state.iSpy);

/*

iSpy onShapeSelected event  {"type":"iSpy/onShapeSelected","payload":{"instanceID":"3bb564b7-b3a3-4ab6-8b5d-f5f325f0a309","imageID":"12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001","shapeIDs":["1738527830048_20"]}}

iSpy onShapeDeleted event  {"type":"iSpy/onShapeDeleted","payload":{"instanceID":"3bb564b7-b3a3-4ab6-8b5d-f5f325f0a309","imageID":"12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001","shapeID":"1738527830048_20","shapeIDs":["1738527830048_20"]}}

{"type":"iSpy/onShapeCreated","payload":{"instanceID":"3bb564b7-b3a3-4ab6-8b5d-f5f325f0a309","imageID":"12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001","shapes":[{"type":"Feature","id":"1738527685135_16","properties":{"allowEditing":true,"metadata":{"date":1718174940000,"title":"Feature - 1"},"selectable":true,"showContextMenu":true,"showPopup":true,"shapeLayer":"","color":"rgb(0, 0, 255)","opacity":1,"weight":2,"fill":false},"geometry":{"type":"Polygon","coordinates":[[[55.3428990173656,25.259696885897107,-28.963917448185384],[55.34276304334975,25.260126419332696,-30.383365186862648],[55.34309639083067,25.260209028160013,-30.278424792923033],[55.343226121023754,25.259777951028546,-30.50442384555936],[55.3428990173656,25.259696885897107,-28.963917448185384]]],"imageCoordinates":[[{"line":13753.561052668827,"sample":15572.376425206876,"lineAbsolute":13753.561052668827,"sampleAbsolute":15572.376425206876},{"line":13656.655934802573,"sample":15547.690434618538,"lineAbsolute":13656.655934802573,"sampleAbsolute":15547.690434618538},{"line":13640.610040920154,"sample":15610.678761231604,"lineAbsolute":13640.610040920154,"sampleAbsolute":15610.678761231604},{"line":13737.515158786407,"sample":15635.364751819941,"lineAbsolute":13737.515158786407,"sampleAbsolute":15635.364751819941},{"line":13753.561052668827,"sample":15572.376425206876,"lineAbsolute":13753.561052668827,"sampleAbsolute":15572.376425206876}]],"groundErrors":[[{"ce":6.453055041209708,"le":7.341316563446149,"terrainType":"SRTM2"},{"ce":6.412346424924919,"le":7.230319121639031,"terrainType":"SRTM2"},{"ce":6.201016902199662,"le":6.910232392810778,"terrainType":"SRTM2"},{"ce":6.0898810517194,"le":6.830149848426044,"terrainType":"SRTM2"},{"ce":6.453055041209708,"le":7.341316563446149,"terrainType":"SRTM2"}]]}}],"errors":[]}}

iSpy image load event  {"type":"iSpy/onImageLoad","payload":{"instanceID":"a8814b47-9b8b-4475-b4fe-f2458f80afbb","imageID":"09FEB24WV030600024FEB09064419-P1BS-508150933010_01_P001","imagePath":"\\\\10.107.137.79\\cache\\img-cm-o2\\16735792\\NCL00000\\Original\\781824876\\FVEY\\UNC\\NL_678428187_24FEB09064419-P1BS-508150933010_01_P001.NTF","imageDate":1707461059000,"imageSecurity":{"classification":"UNCLASSIFIED","sciControl":"","dissemination":""},"imageExtents":{"upperLeft":{"lat":25.4427777777778,"lon":56.2294444444445,"ele":636.0275373048098,"ce":0,"le":0},"upperRight":{"lat":25.4225,"lon":56.3922222222222,"ele":-24.945608075603865,"ce":0,"le":0},"bottomLeft":{"lat":25.2975,"lon":56.2294444444445,"ele":565.9328872504133,"ce":0,"le":0},"bottomRight":{"lat":25.2775,"lon":56.3916666666667,"ele":-24.57740570879938,"ce":0,"le":0}},"imageGuide":"GUIDE://5003/342a8c3c572be913aa121980b87f104a4501b6f500000/ndl-w/u/678428187"}}
 */
