// components/Config.tsx
import createConfig from 'react-runtime-config';

/**
 * `useConfig` and `useAdminConfig` are now React hooks that you can use in your app.
 *
 * `useConfig` provides config getter & setter, `useAdminConfig` provides data in order
 * to visualize your config map with ease. More on this further down.
 */
export const { useConfig, useAdminConfig } = createConfig({
  namespace: 'gunslingerConfig',
  schema: {
    SECURITY_COLOR: {
      type: 'string',
      enum: ['green' as const, 'red' as const, 'yellow' as const], // `as const` is required to have nice autocompletion
      description: 'Security classification',
    },
    SECURITY_CLASSIFICATION: {
      type: 'string',
      description: 'Classification string', // config without `default` need to be provided into `window.MY_APP_CONFIG`
    },
    MAP_BASE_LAYER_URL: {
      type: 'string',
      description: 'Map base imagery layer',
      default: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    },
    MAP_DISPLAY_PROJECT_LOGO: {
      type: 'boolean',
      description: 'Show project logo on map',
      default: true,
    },
    MAP_PROJECT_LOGO_URL: {
      type: 'string',
      description: 'The project logo source',
      default: './shadowGunslinger.svg',
    },
    MAP_DISPLAY_SECONDARY_LOGO: {
      type: 'boolean',
      description: 'Show secondary logo on map',
      default: true,
    },
    MAP_SECONDARY_LOGO_URL: {
      type: 'string',
      description: 'The secondary logo source',
      default: './tridentspectre.svg',
    },
    MAP_DISPLAY_LOGO3: {
      type: 'boolean',
      description: 'Show logo 3 on map',
      default: true,
    },
    MAP_LOGO3_URL: {
      type: 'string',
      description: 'The logo 3 source',
      default: './logo3.svg',
    },
    MAP_DISPLAY_LOGO4: {
      type: 'boolean',
      description: 'Show logo 4 on map',
      default: true,
    },
    MAP_LOGO4_URL: {
      type: 'string',
      description: 'The logo 4 source',
      default: './logo4.svg',
    },
    MAP_ENABLE_MOUSEWHEEL_ZOOM: {
      type: 'boolean',
      description: 'Allow use of mouse wheel to zoom',
      default: true,
    },
    MAP_DEFAULT_ZOOM: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Default map zoom',
      min: 0,
      max: 20,
      default: 3, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    MAP_TILE_SIZE: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Map tile size',
      min: 0,
      max: 2048,
      default: 512, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    MAP_PROJECTION: {
      type: 'string',
      enum: ['CRS.EPSG4326' as const, 'CRS.EPSG3857' as const], // `as const` is required to have nice autocompletion
      description: 'Map projection',
    },
    TITLE: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Application title',
      default: 'Shadow Gunslinger', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    DESCRIPTION: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Application description',
      default: 'Shadow Gunslinger', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    COMPANY_RENDER: {
      type: 'boolean', //default off unless needed for demo
      description: 'Display GMDS info',
      default: false,
    },
    COMPANY_NAME: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Company name',
      default: 'Grey Matter Defense Solutions, LLC', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    COMPANY_URL: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Company URL',
      default: 'https://www.greymattersdefense.com/', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    DOCUMENTATION_URL: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Documentation URL',
      default: 'https://www.greymattersdefense.com/', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    DOCUMENTATION_NAME: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Documentation Name',
      default: 'Documentation', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    NAV_ITEM1_URL: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Item1 URL',
      default: '', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    NAV_ITEM1_NAME: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Item1 Name',
      default: '', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    NAV_ITEM2_URL: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Item 2URL',
      default: '', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    NAV_ITEM2_NAME: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Item2 Name',
      default: '', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    DEFAULT_TEAM: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Default application coordinates',
      enum: [
        'white' as const,
        'blue' as const,
        'red' as const,
        'green' as const,
        'yellow' as const,
        'orange' as const,
        'magenta' as const,
        'maroon' as const,
        'purple' as const,
        'dark blue' as const,
        'cyan' as const,
        'teal' as const,
        'dark green' as const,
        'brown' as const,
      ],
      // parser: value => {
      //   if (typeof value === "string") {
      //     // The type will be inferred from the return type
      //     return value as DisplayCoordinates;
      //   }
      //   // This error will be shown if the `window.MY_APP_CONFIG.monitoringLink` can't be parsed or if we `setConfig` an invalid value
      //   throw new Error("DEFAULT_COORDINATES invalid!");
      // },
      default: 'white', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    DEFAULT_COORDINATES: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Default application coordinates',
      enum: ['degrees' as const, 'decimal' as const, 'mgrs' as const],
      // parser: value => {
      //   if (typeof value === "string") {
      //     // The type will be inferred from the return type
      //     return value as DisplayCoordinates;
      //   }
      //   // This error will be shown if the `window.MY_APP_CONFIG.monitoringLink` can't be parsed or if we `setConfig` an invalid value
      //   throw new Error("DEFAULT_COORDINATES invalid!");
      // },
      default: 'degrees', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    DEFAULT_UNITS: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Default application units',
      enum: ['meters' as const, 'nm' as const, 'miles' as const],
      default: 'meters', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    THEME: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Application theme',
      enum: ['dark' as const, 'light' as const, 'system' as const],
      default: 'dark', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    SGS_COLLECTION_REQUEST_MARKER: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      enum: ['CircleMarker' as const, 'Marker' as const, 'SGS_ICON' as const], // `as const` is required to have nice autocompletion
      description: 'Collection Request Icon',
      default: 'CircleMarker', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    SGS_COLLECTION_REQUEST_URL: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'SGS Web app services Collection request url',
      default: '', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    SGS_WEBSOCKET_URL: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'SGS Web websocket url',
      default: '', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    MAP_GEOTIFF_OPACITY: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Geotiff opacity',
      default: 0.7, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    MAP_GOTO_ZOOM_LEVEL: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Zoom level on goto',
      default: 15, // config with `default` don't have to be set on
      // `window.MY_APP_CONFIG`
    },
    ATR_MARKER_TO_DETECT_ZOOM_LEVEL: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Zoom level where markers turn to bounding boxes',
      min: 0,
      max: 20,
      default: 11, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    ATR_ZOOM_TO_LEVEL: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Zoom to level',
      min: 0,
      max: 20,
      default: 18, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    ATR_DETECT_BORDER_WIDTH: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Detect border width',
      min: 1,
      max: 10,
      default: 2, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    ATR_DETECT_COLOR: {
      type: 'string',
      enum: [
        'red' as const,
        'blue' as const,
        'cyan' as const,
        'yellow' as const,
        'lime' as const,
        'green' as const,
        'white' as const,
      ], // `as const` is required to have nice autocompletion
      description: 'Detect box color',
    },
    ATR_VERIFIED_DETECT_COLOR: {
      type: 'string',
      enum: [
        'lime' as const,
        'red' as const,
        'blue' as const,
        'cyan' as const,
        'yellow' as const,
        'green' as const,
        'white' as const,
      ], // `as const` is required to have nice autocompletion
      description: 'Verified detect box color',
    },
    SGS_ATR_URL: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'SGS Web app services ATR url',
      default: '', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    // port: {
    //   type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
    //   description: 'Backend port',
    //   min: 1,
    //   max: 65535,
    //   default: 8000, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    // },
    SGS_OPS_BOX_RENDER: {
      type: 'boolean',
      description: 'Render Ops Box',
      default: true,
    },
    SGS_OPS_BOX_COLOR: {
      type: 'string',
      description: 'Default Ops Box color',
      enum: [
        'red' as const,
        'blue' as const,
        'cyan' as const,
        'yellow' as const,
        'lime' as const,
        'green' as const,
        'white' as const,
        'orange' as const,
      ],
      default: 'orange',
    },
    SGS_OPS_BOX_WIDTH: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Ops Box border width',
      min: 1,
      max: 10,
      default: 2, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    SGS_OPS_BOX_FILL_OPACITY: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Ops Box fill opacity',
      min: 0,
      max: 1,
      default: 0, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    SGS_IPTA_PROPOSED_TASK_RENDER: {
      type: 'boolean',
      description: 'Render IPTA Proposed Task',
      default: true,
    },
    SGS_IPTA_PROPOSED_TASK_COLOR: {
      type: 'string',
      description: 'Default IPTA Proposed Task color',
      enum: [
        'red' as const,
        'blue' as const,
        'cyan' as const,
        'yellow' as const,
        'lime' as const,
        'green' as const,
        'white' as const,
        'orange' as const,
      ],
      default: 'blue',
    },
    SGS_IPTA_PROPOSED_TASK_OPACITY: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Default IPTA Proposed Task opacity',
      min: 0,
      max: 1,
      default: 0.065,
    },
    SGS_IPTA_COMPLETED_TASK_COLOR: {
      type: 'string',
      description: 'Default IPTA Completed Task color',
      enum: [
        'red' as const,
        'blue' as const,
        'cyan' as const,
        'yellow' as const,
        'lime' as const,
        'green' as const,
        'white' as const,
        'orange' as const,
      ],
      default: 'blue',
    },
    SGS_IPTA_COMPLETED_TASK_OPACITY: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Default IPTA Completed Task opacity',
      min: 0,
      max: 1,
      default: 0.065,
    },
    SGS_ATR_DETECTION_DOWNLOAD_BASE_FILENAME: {
      type: 'string',
      description: 'Default filename for detection downloads',
      default: 'ATR-Detections',
    },
    SGS_DISPLAY_COT_INFO: {
      type: 'boolean',
      description: 'Display COT Info',
      default: false,
    },
    SGS_REQUEST_COVERAGE_RENDER: {
      type: 'boolean',
      description: 'Display request coverage',
      default: true,
    },
    SGS_COVERED_REQUEST_BOX_COLOR: {
      type: 'string',
      description: 'Default Covered Request color',
      enum: [
        'red' as const,
        'blue' as const,
        'cyan' as const,
        'yellow' as const,
        'lime' as const,
        'green' as const,
        'white' as const,
        'orange' as const,
      ],
      default: 'cyan',
    },
    SGS_COVERED_REQUEST_BOX_OPACITY: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Covered Request fill opacity',
      min: 0,
      max: 1,
      default: 0.0, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    SGS_COVERED_REQUEST_LINE_WIDTH: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Covered Request border width',
      min: 1,
      max: 10,
      default: 2, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    SGS_UNCOVERED_REQUEST_BOX_COLOR: {
      type: 'string',
      description: 'Default Uncovered Request color',
      enum: [
        'red' as const,
        'blue' as const,
        'cyan' as const,
        'yellow' as const,
        'lime' as const,
        'green' as const,
        'white' as const,
        'orange' as const,
      ],
      default: 'red',
    },
    SGS_UNCOVERED_REQUEST_BOX_OPACITY: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Uncovered Request fill opacity',
      min: 0,
      max: 1,
      default: 0.035, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    SGS_UNCOVERED_REQUEST_LINE_WIDTH: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Uncovered Request border width',
      min: 1,
      max: 10,
      default: 2, // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    SGS_SAD_SNAPSHOT_URL: {
      type: 'string',
      description: 'SGS SAD Coverage Endpoint',
      default: '',
    },
    SGS_SAD_TASK_URL: {
      type: 'string',
      description: 'SGS SAD Repo Endpoint',
      default: '',
    },
    RENDER_DELETED_COLLECTION_REQUESTS: {
      type: 'boolean',
      description: 'Render Deleted RFIs',
      default: true,
    },
    RENDER_DISMISSED_COLLECTION_REQUESTS: {
      type: 'boolean',
      description: 'Render Dismissed RFIs',
      default: true,
    },
    INTEL_DATA_SYMBOL_SIZE_PIXELS: {
      type: 'number',
      description: 'Size of intel data icon in pixels',
      default: 30,
    },
    SGS_INTELDATA_URL: {
      type: 'string',
      description: 'SGS Web app services IntelData url',
      default: '',
    },
    GIMS_NAME: {
      type: 'string',
      description: 'GIMS',
      default: '',
    },
    GIMS_URL: {
      type: 'string',
      description: 'GIMS URL',
      default: '',
    },
    DEFAULT_SIDC: {
      type: 'string',
      description: 'Default 2525c symbol used if no symbol or error in the data',
      default: 'SUZ',
    },
    ISPY_HOST: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'iSpy Host',
      default: 'https://cgsweb-ispy.nga.mil/', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    ISPY_VIEW_IMAGE_PATH: {
      type: 'string', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'iSpy View Image Path',
      default: 'cgswebjs/', // config with `default` don't have to be set on `window.MY_APP_CONFIG`
    },
    ISPY_REMOTE_ENABLED: {
      type: 'boolean',
      description: 'Enable/Disable iSpy Remote Interface',
      default: true,
    },
    ISPY_DETECT_COLOR: {
      type: 'string',
      description: 'Default iSpy Detect color',
      enum: [
        'red' as const,
        'blue' as const,
        'cyan' as const,
        'yellow' as const,
        'lime' as const,
        'green' as const,
        'white' as const,
        'orange' as const,
      ],
      default: 'yellow',
    },
    ISPY_SYNC_APP_ISPY_VIEWPORT: {
      type: 'boolean',
      description: 'Sync webapp view to the iSpy viewport',
      default: true,
    },
    ISPY_SYNC_DETECT_SELECT_IN_SPY: {
      type: 'boolean',
      description: 'Sync iSpy shape selection to webapp detect select ',
      default: true,
    },
    ISPY_CLEAR_DETECTS_ON_IMAGE_CHANGE: {
      type: 'boolean',
      description: 'Clear detects from image when image is unloaded/reloaded',
      default: false,
    },
    // ISPY_OPEN_DETECT_IMAGES_IN_SEPARATE_INSTANCE: {
    //   type: 'boolean',
    //   description: 'Open ATR detect images in separate instance',
    //   default: true,
    // },
    ISPY_SHAPE_BATCH_SIZE: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Number of shapes to be sent in a single create request. -1 and 0 will send all',
      min: -1,
      max: 300,
      default: 50,
    },
    ISPY_BUSY_USER_WAIT_TIMEOUT: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Number of seconds to wait before allowing user to dismiss ispy busy screen',
      min: 5,
      max: 500,
      default: 30,
    },
    ISPY_SYNC_SCALE_OFFSET: {
      type: 'number', // This schema can be retrieved after in `useAdminConfig().fields`
      description: 'Scale offset to help align webapp and iSpy viewpoint scaling',
      min: -5,
      max: 5,
      default: 0,
    },
  },
});
