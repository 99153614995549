import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../types/RequestStatus';
import { getCompletedTasksByOpsBoxId } from './CompleteTasksSlice';
import { useConfig } from '../services/Config';

type OpsBoxData = { id: string; data: any };

interface ExtendedEntityAdapterState {
  selectedId: null | string;
  updated: null | Date;
  geojson: null | any;
  status: RequestStatus;
}

const initialState: ExtendedEntityAdapterState = {
  selectedId: null,
  updated: null,
  geojson: null,
  status: RequestStatus.NOT_LOADED,
};

const clearState = state => {
  state.entities = {};
  state.ids = [];
  state.selectedId = null;
  state.geojson = null;
  state.updated = new Date();
  state.status = RequestStatus.NOT_LOADED;
};

const opsBoxAdapter = createEntityAdapter<OpsBoxData>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: opsbox => opsbox.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

export const opsBoxSlice = createSlice({
  name: 'opsBox',
  initialState: opsBoxAdapter.getInitialState(initialState),
  reducers: {
    instanceAdded: opsBoxAdapter.addOne,
    instanceRemoved: opsBoxAdapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(onOpsBox.rejected, (state, action) => {
      console.log('onOpsBox rejected');
      state.updated = new Date();
      state.status = RequestStatus.ERROR;
    });
    builder.addCase(onOpsBox.pending, (state, action) => {});
    builder.addCase(onOpsBox.fulfilled, (state, action) => {
      console.log('onOpsBox fulfilled');
      if (action.payload) {
        opsBoxAdapter.upsertOne(state, action.payload);
      }
      state.updated = new Date();
      state.status = RequestStatus.SUCCESS;
    });
  },
});

// 20250329 -- DEV NOTE: sure sure I love this, but it allow the slice to populate this and the coverage
// and not be driven by the component...TBD on how many times I refactor this.
export const onOpsBox = createAsyncThunk('opsBox/onOpsBox', async (arg: any, thunkAPI) => {
  console.log('opsBox/onOpsBox entry ' + arg);
  if (!arg) {
    return thunkAPI.rejectWithValue('Bad data');
  }
  // call the ipta completed task action
  const host = window['gunslingerConfig']['SGS_SAD_SNAPSHOT_URL'];
  // const host = getConfig('SGS_SAD_SNAPSHOT_URL');
  const url = `${host}/${arg.id}`;
  console.log(`coverage request: ${url}`);
  thunkAPI.dispatch(getCompletedTasksByOpsBoxId(url));
  const opsBoxEntity = { id: arg.id, data: arg };
  return opsBoxEntity;
});
