import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { RequestStatus } from '../types/RequestStatus';
import { dismissedState, selectAllAtrDetects, unverifiedState, verifiedState } from './AtrSlice';
import { selectCollectionRequests, selectSubmittedAndRequestedCollectionRequests } from './CollectionRequestSlice';

type CoverageDataParam = {
  completed: boolean;
  age: number;
};
type CompleteTaskMetaData = { visible: boolean };

type IPTACompletedTask = { id: string; data: any; metaData: CompleteTaskMetaData };

const emptyGeoJSON = JSON.parse(JSON.stringify({ type: 'FeatureCollection', features: [] }));

interface ExtendedEntityAdapterState {
  selectedId: null | string;
  updated: null | Date;
  geojson: null | any;
  status: RequestStatus;
}

const initialState: ExtendedEntityAdapterState = {
  selectedId: null,
  updated: null,
  geojson: emptyGeoJSON,
  status: RequestStatus.NOT_LOADED,
};

const iptaCompletedTaskAdapter = createEntityAdapter<IPTACompletedTask>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: snapshot => snapshot.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

const clearState = state => {
  state.entities = {};
  state.ids = [];
  state.selectedId = null;
  state.geojson = null;
  state.updated = new Date();
  state.status = RequestStatus.NOT_LOADED;
};

export const completedCoverageSlice = createSlice({
  name: 'completedCoverage',
  initialState: iptaCompletedTaskAdapter.getInitialState(initialState),
  reducers: {
    instanceAdded: iptaCompletedTaskAdapter.addOne,
    instanceRemoved: iptaCompletedTaskAdapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(getCompletedTasksByOpsBoxId.rejected, (state, action) => {
      console.log('getCompletedTasksByOpsBoxId rejected');
      state.updated = new Date();
      state.status = RequestStatus.ERROR;
    });
    builder.addCase(getCompletedTasksByOpsBoxId.pending, (state, action) => {});
    builder.addCase(getCompletedTasksByOpsBoxId.fulfilled, (state, action) => {
      console.log('getCompletedTasksByOpsBoxId fulfilled');
      if (action.payload) {
        iptaCompletedTaskAdapter.upsertOne(state, action.payload.entity);
        //TEMP---not best approach for this, but lazy for quick testing
        // state.geojson = buildGeoJson([action.payload.entity], {completed: false, age: -1 });
      }
      state.updated = new Date();
      state.status = RequestStatus.SUCCESS;
    });
  },
});

// 20250329 -- TODO -- trigger this based on loaded opsboxes
export const getCompletedTasksByOpsBoxId = createAsyncThunk(
  'iptaCompletedTasks/getCompletedTasksByOpsBoxId',
  async (arg: any, thunkAPI) => {
    try {
      console.log('iptaCompletedTasks/getCompletedTasksByOpsBoxId entry ' + arg);
      const response = await getData(arg);
      console.log(`getCompletedTasksByOpsBoxId response: ${response}`);
      if (!response && !response.snapshot) {
        return thunkAPI.rejectWithValue('Bad data');
      }
      // const entities = response.snapshot.operations?.map(entry => {
      //   console.log(`Processing operations niirs ${entry.niirs}`);
      //   return entry;
      // });
      const entity = { id: response.snapshot.opsboxIdentifier, data: response, metaData: { visible: true } };
      return { response, entity: entity };
    } catch (ex) {
      //@ts-ignore
      console.error(`IPTA Coverage Error occurred calling '${arg}', code=${ex.code}`);
      return thunkAPI.rejectWithValue(ex);
    }
  },
);

const getData = async (requestUrl: string) => {
  const client = axios.create();
  axiosRetry(client, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
  });
  const response = await client.get(requestUrl);
  console.log(`axios response ${response}`);
  return response.data;
};


const buildGeoJson = (entities, args:CoverageDataParam) => {
  console.log(`buildGeoJson ${JSON.stringify(entities)}`);
  if (entities == null || entities.length ===0) {
    return emptyGeoJSON;
  }
  const features = entities.flatMap(e => {
    return e.metaData.visible ? buildGeoJsonFeatures(e.data) : [];
  });

  const gjfc: any = {
    type: 'FeatureCollection',
    features: features,
  };
  return gjfc;
};

const buildGeoJsonFeatures = payload => {
  const operations = payload.snapshot.operations;
  return operations.map(p => {
    return buildGeoJsonFeature(payload.snapshot, p);
  });
};

const buildGeoJsonFeature = (snapshot, payload) => {
  // const geo = JSON.parse(payload.geometryOfCoverage);
  const geo = payload.geometryOfCoverage;

  const feature: any = {
    type: 'Feature',
    properties: {
      imageId: payload.imageID,
      niirs: payload.niirs,
      cloudCoverPercentage: payload.cloudCoverPercentage,
      collected: payload.collected,
      dateTime: payload.dateTime,
      opsBoxId: snapshot.opsboxIdentifier,
    },
    geometry: geo,
  };
  return feature;
};

export const {
  selectById: selectCoverageById,
  selectIds: selectCoverageIds,
  selectEntities: selectCoverageEntities,
  selectAll: selectAllCoverage,
  selectTotal: selectTotalCoverage,
} = iptaCompletedTaskAdapter.getSelectors((state: any) => state.completedCoverage);

const geojson = state => state.completedCoverage.geojson;
const updatedState = state => state.completedCoverage.updated;

export const selectCompletedCoverageUpdated = createSelector([updatedState], x => x);
// export const selectCompletedCoverageGeoJSON = createSelector([geojson], x => x);


// export selectGeoJSON = createSelector([selectAllCoverage]);

export const selectCompletedCoverageGeoJSON = (args: CoverageDataParam) =>
  createSelector(
    [state => selectAllCoverage(state), state => selectSubmittedAndRequestedCollectionRequests(state)],
    (coverage, rfis) => {
      const filteredCoverage = coverage.filter(data => {
        // if (!args) {
        //   return true;
        // }
        if (!data.metaData.visible) {
          return false;
        }
        return rfis.requests.some(rfi => {
          return true;
          // return rfi.properties['OpsBox'] && rfi.properties['OpsBox'].id === data.id;
        });
      });
      return buildGeoJson(filteredCoverage, args);
    },
  );

/*

 {
      "type": "Feature",
      "properties": { "name": "Polygon 1", "area_km2": 25 },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-104.991531, 39.739236],
            [-104.972389, 39.732236],
            [-104.965389, 39.752236],
            [-104.985531, 39.759236],
            [-104.991531, 39.739236]
          ]
        ]
      }
    }

 */
